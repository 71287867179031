/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@4.6.0/dist/js/bootstrap.min.js
 * - /npm/isotope-layout@3.0.6/dist/isotope.pkgd.min.js
 * - /npm/wow.js@1.2.2/dist/wow.min.js
 * - /npm/blazy@1.8.2/blazy.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/languages@0.1.3/languages.min.js
 * - /npm/jquery-dateformat@1.0.4/dist/jquery-dateformat.min.js
 * - /npm/@emailjs/browser@3.6.2/dist/email.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
